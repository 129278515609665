import { isEmpty } from 'lodash';
import { getChecklistDefinitionQuestionGroupsWithQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import { RATING_MODE } from './constants';

const getMin = ratingValues => {
  if (Math.max(...ratingValues) === Math.min(...ratingValues)) {
    return 0;
  }
  return Math.min(...ratingValues);
};

const getMax = ratingValues => {
  if (Math.max(...ratingValues) === Math.min(...ratingValues) && Math.max(...ratingValues) < 0) {
    return Math.abs(Math.max(...ratingValues));
  }
  return Math.max(...ratingValues);
};

export const prepareFormQuestions = (state, answers, reviewQuestionsAnswers) => {
  const questionsResource = state.questionsResource.byIds;
  const { currentChecklist } = state.uiChecklistManager;
  const checklistDefinition = getChecklistsDefinitionsByIds(state)[
    currentChecklist?.checklistDefinitionId
  ];

  const isRatingModePercentage = checklistDefinition.ratingMode === RATING_MODE.PERCENTAGE;
  const questionToGroupBindingsResource = state.questionToGroupBindingsResource.byIds;
  const questionGroupsChecklistDefinition = getChecklistDefinitionQuestionGroupsWithQuestions(
    state,
    checklistDefinition
  );
  const questionToGroupSubgroupBindingsResource =
    state.questionToGroupSubgroupBindingsResource.byIds;

  const formQuestions = [];
  const questionsIds = [];

  const questionGroups = [];
  const questionGroupSubgroups = [];
  questionGroupsChecklistDefinition.flatMap(group => {
    questionGroups.push(group.id);
    if (!isEmpty(group.subgroups)) {
      group.subgroups.flatMap(subgroup => {
        questionGroupSubgroups.push(subgroup.id);
      });
    }
  });

  Object.keys(questionToGroupBindingsResource).forEach(key => {
    if (questionGroups.includes(questionToGroupBindingsResource[key].questionGroupId)) {
      questionsIds.push(questionToGroupBindingsResource[key].questionId);
    }
  });
  Object.keys(questionToGroupSubgroupBindingsResource).forEach(key => {
    if (
      questionGroupSubgroups.includes(
        questionToGroupSubgroupBindingsResource[key].questionGroupSubgroupId
      )
    ) {
      questionsIds.push(questionToGroupSubgroupBindingsResource[key].questionId);
    }
  });

  if (!isEmpty(answers)) {
    answers.forEach(elem => {
      const question = questionsResource[elem.questionId];
      const { ratingValues } = question;
      formQuestions.push({
        question_id: questionsResource[elem.questionId].shortId,
        min_value: isRatingModePercentage ? getMin(ratingValues) : question.min,
        max_value: isRatingModePercentage ? getMax(ratingValues) : question.max,
        origin_max_value: question.max,
        score: elem.value ?? false,
        has_answer: elem.value || elem.value === 0
      });
    });
  } else {
    reviewQuestionsAnswers.data.forEach(elem => {
      const shortId = elem['question-id'] ? elem['question-id'] : elem.questionId;
      const question = Object.values(questionsResource).find(
        question => question.shortId === shortId
      );
      const { ratingValues } = question;

      const min =
        !!elem['min-value'] || elem['min-value'] === 0 ? elem['min-value'] : elem.minValue;
      const max =
        !!elem['max-value'] || elem['max-value'] === 0 ? elem['max-value'] : elem.maxValue;

      formQuestions.push({
        question_id: elem['question-id'] ? elem['question-id'] : elem.questionId,
        min_value: isRatingModePercentage ? getMin(ratingValues) : min,
        max_value: isRatingModePercentage ? getMax(ratingValues) : max,
        origin_max_value: max,
        score: elem.score ?? false,
        has_answer: elem.score || elem.score === 0
      });
    });
  }

  if (!isEmpty(questionsIds)) {
    questionsIds.forEach(id => {
      if (questionsResource[id]) {
        const question = questionsResource[id];
        const { ratingValues } = question;

        const isIdPresent = formQuestions.some(obj => obj.question_id === question.shortId);
        if (!isIdPresent) {
          formQuestions.push({
            question_id: question.shortId,
            min_value: isRatingModePercentage ? getMin(ratingValues) : question.min,
            max_value: isRatingModePercentage ? getMax(ratingValues) : question.max,
            origin_max_value: question.max,
            score: false,
            has_answer: false
          });
        }
      }
    });
  }

  return formQuestions;
};
